@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap");

html {
  font-size: 18px;
}

.editor-toolbar {
  min-height: 10px;
  box-shadow: 0 0 0 0.2px black;
}

.editor {
  border: 1px solid #e9e9e9;
  color: #000;
  font-family: "Source Sans Pro", sans-serif;
  min-height: 150px;
  box-shadow: 0 0 0 0.2px black;
}

.editorPrivacy {
  border: 1px solid #e9e9e9;
  color: #000;
  font-family: "Source Sans Pro", sans-serif;
  min-height: 300px;
  max-height: 300px;
  box-shadow: 0 0 0 0.2px black;
  font-size: 15px;
}

.editor-qr {
  border: 1px solid #e9e9e9;
  color: #000;
  font-family: "Source Sans Pro", sans-serif;
  width: 370px;
  max-height: 52px;
}

.editor h2,
.editor h3,
.editor h4 {
  color: #112e51;
}

.editor h2 {
  font-size: 1.555555556rem;
}

.editor h3 {
  font-size: 1.333333333rem;
}

.editor h4 {
  font-size: 1rem;
}

.editor a {
  color: #0071bc;
  text-decoration: none;
}

.editor a:hover {
  text-decoration: underline;
  cursor: pointer;
}

pre {
  border: 1px solid #e9e9e9;
  padding: 1rem;
  background-color: #f4f4f4;
}

.container {
  display: flex;
  justify-content: center;
}

.container-form {
  display: flex;
  justify-content: center;
}

.stack-background {
  display: flex;
  flex-direction: column;
  width: 830px;
  padding: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: black;
  background-color: rgb(255, 255, 255);

}

.stack-background-for-loading {
  display: flex;
  flex-direction: column;
  width: 830px;
  height: 200px;
  padding: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: black;
  background-color: transparent;
  justify-content: center;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.row-pickup {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.column {
  flex: 1;
}

.column-example-second {
  flex: 1;
  position: absolute;
  top: 0px;
  right: -280px;
}

.column-example-third{
  flex: 1;
  position: absolute;
  bottom: -20px;
  right: -280px;
}

@media only screen and (max-width: 768px) {
  .row {
    flex-direction: row; 
  }
  .column {
    flex: 1 1 50%; 
  }
}

.style-paper-only {
  background: linear-gradient(
    to bottom left,
    var(--firstshade-color),
    var(--secondshade-color),
    var(--thirdshade-color),
    var(--fourth-color)
  );
}

.qr-code-generator {
  display: flex;
  justify-content: space-between;
  font-family: Arial, sans-serif;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  background-color: white;
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
  /* backdrop-filter: blur(8.5px); */
  /* -webkit-backdrop-filter: blur(8.5px); */
  display: flex;
  width: 830px;
  min-height: 680px;
  position: relative;
}

.left-side {
  flex: 1;
  color: black;
  padding: 10px;
}

.right-side   {
  flex: 1;
  color: black;
  padding: 10px;
}

h2 {
  font-size: 20px;
  margin-bottom: 15px;
}

input[type="file"] {
  margin-bottom: 15px;
}

textarea {
  width: calc(100% - 10px); 
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.label {
  margin-bottom: 5px;
  font-weight: bold;
}

.radios {
  margin-bottom: 15px;
}

.radios label {
  margin-right: 10px;
}

.preview {
  width: 100%;
  height: 400px;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.preview-button {
  width: 100%;
  height: 150px;
  overflow: hidden;
  border: 1px solid #ccc;
  margin-top: 50px;
  border-radius: 8px;
}

.paper {
  position: relative;
  width: 100%;
  height: 100%;
}

.text-above,
.text-below {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
}

.text-above {
  top: 50px;
}

.text-below {
  bottom: 20px;
}

.logo-container {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  max-width: 150px;
  max-height: 150px;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.qr-code-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-topic-switch {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin-left: 20px;
  position: absolute;
  right: -35px;
  bottom: -30px;
}

.company-information {
  color: black;
}

.first-part {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.first-part {
  display: flex;
  flex-direction: column;
}

.company-information {
  margin-bottom: 5px;
}

.tax-number-type {
  margin-bottom: 5px;
}

.field-long {
  margin-bottom: 5px;
}

.branch-display {
  margin-bottom: 5px;
}

.branch-number {
  color: black;
}

.label {
  font-size: 14px;
  font-weight: bold;
  align-self: left;
  margin-bottom: 3px;
}

.input {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dropdown {
  width: 100%;
  min-width: 25%;
}

.option {
  padding: 8px;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}

.new-section-container {
  display: flex;
  flex-direction: column;
}

.responsive-element {
  display: flex;
}

.field-long {
  width: 100%;
}

.label {
  font-size: 1.4rem;
  font-weight: bold;
  align-self: center;
}

.input {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.stack {
  margin-top: 10px;
}

.new-section-container {
  display: flex;
  justify-content: space-between;
}

.taxNumberType,
.branchDisplay,
.branchNumber,
.field-long,
.branchNumber,
.first-part,
.company-information {
  width: 100%;
}

.build-number-address,
.street,
.province,
.district,
.subdistrict,
.postcode {
  width: 100%; 
}

.build-number-address .field-long,
.street .field-long,
.province .field-long,
.district .field-long,
.subdistrict .field-long,
.postcode .field-long {
  margin-bottom: 5px;
}

.build-number-address .label,
.street .label,
.province .label,
.district .label,
.subdistrict .label,
.postcode .label {
  font-size: 14px;
  font-weight: bold;
  align-self: left;
}