.wrap {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: white;
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
  /* backdrop-filter: blur(8.5px); */
  /* -webkit-backdrop-filter: blur(8.5px); */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 830px;
}

.wrap-paper {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  background-color: whitesmoke;
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
  /* backdrop-filter: blur(8.5px); */
  /* -webkit-backdrop-filter: blur(8.5px); */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 320px;
  font-size: 15px;
  background-color: #ffffff;
}

.wrap-paper-text {
  background-color: whitesmoke;
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
  /* backdrop-filter: blur(8.5px); */
  /* -webkit-backdrop-filter: blur(8.5px); */
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 15px;
  height: 120px;
}

.wrap-example {
  padding: 2rem;
  background: var(--background-color);
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
  /* backdrop-filter: blur(8.5px); */
  /* -webkit-backdrop-filter: blur(8.5px); */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 200px;
  margin-left: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .column {
    flex: 1 1 100%; 
  }
}

.header-part {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-left: 50px;
  margin-right: 50px;
}

.cardStyle {
  padding: 10px;
  background-color: #ffffff;
  height: 120px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
}

.card-style-example-second {
  padding: 10px;
  background-color: #ffffff;
  height: 50px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.topic-header {
  color: var(--primary-color);
}

.circle-active-background {
  background-color: var(--accent-color-1);
  color: var(--secondary-color);
}

.circle-not-active-background {
  background-color: var(--primary-color);
  color: var(--background-color);
}

.accent-color-apply {
  color: var(--accent-color-1);
  text-align: left;
  font-size: 20px;
  padding-bottom: 50px;
}

.header-topic-switch {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin-left: 20px;
  position: absolute;
  right: 1px;
  top: 1px;
}

.palette-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr); 
  grid-gap: 15px; 
  padding-bottom: 15px;
}

.palette-container-qr {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  grid-gap: 1px; 
}

.palette {
  width: 2.5rem;
  padding: 0.8rem 1rem;
  justify-content: flex-start;
  border: 0.1rem solid rgb(182, 177, 241);
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.palette-loading {
  width: 2.5rem;
  padding: 0.8rem 1rem;
  justify-content: flex-start;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.palette-qr-loading {
  width: 40px;
  height: 40px;
  padding: 0.2rem 1rem;
  justify-content: flex-start;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.palette-qr {
  width: 2rem;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  justify-content: flex-start;
  border: 0.1rem solid rgb(154, 168, 230);
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-bottom: 10px;
}

.palette:hover {
  transform: translate(-0.25rem, -0.25rem);
  box-shadow: 0.25rem 0.25rem rgb(108, 128, 218);
  color: var(--accent-color-1);
  border-color: rgb(108, 128, 218);
}

.palette-qr:hover {
  transform: translate(-0.25rem, -0.25rem);
  box-shadow: 0.25rem 0.25rem rgb(108, 128, 218);
  color: var(--fourthshade-color);
  border-color: rgb(108, 128, 218);
}

.palette-Inner-wrap {
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  border-radius: 0.5rem;
  pointer-events: none;
}

.palette-Inner-wrap-qr {
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  border-radius: 0.5rem;
  pointer-events: none;
}

.palette-color {
  width: 15px;
  height: 15px;
  margin-right: -4px;
  margin-left: -4px;
  border-style: solid;
  border-width: 3px;
  border-color: #181818;
  border-radius: 100%;
  background-color: var(--background-color);
}

.palette-colorLoading {
  width: 5rem;

  padding: 0.8rem 1rem;
  width: 2.5rem;
}

.palette-color-qr-loading {
  padding: 0px 10px;
}

.palette-color-qr{
  width: 5px;
  height: 5px;
  margin-right: -1px;
  margin-left: -1px;
  border-style: solid;
  border-width: 3px;
  border-color: #181818;
  border-radius: 100%;
  background-color: var(--background-color);
}

.style-paper-only {
  max-width: 450px;
  height: 450px;
  background: linear-gradient(
    to bottom left,
    var(--secondshade-color),
    var(--thirdshade-color),
    var(--fourthshade-color)
  );
}

.backgroundtext {
  background-color: var(--firstshade-color);
}

.firstshade {
  background-color: var(--firstshade-color);
}


