.main-pages {
  width: 100%;
  height: auto;
  /* padding: 10px 30px 30px; */
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  min-Height: 100vh;
}

.main-pages-tiny {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
  min-Height: 100vh;
  background-color: #cccccc;
}

.control-main-pages {
  display: flex;
  flex-direction: column;
  /* align-items: start;
  width: 85%; */
  justify-content: center;
  align-items: center;
}


.transition-all {
  transition-property: all;
  transition-duration: 0.3s;
}

.custom-button-close {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
  transition: background-color 0.3s;
}

.icon-cross {
  color: black; /* Initial color of the icon */
  transition: color 0.3s;
}

.custom-button-close:hover .iconCross {
  color: white; /* Color of the icon on hover */
}

.button-icon {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px; 
  transform: scale(5);
  transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
  margin: 0 10px; 
  color: black;
}

.button-icon:hover {
  transform: scale(1.2); 
}

.button-icon.hovered {
  color: black;
  transform: scale(1.2);
}

.icon {
  font-size: 24px; /* Adjust the default font size */
  transition: transform 0.2s ease-in-out; /* Add a smooth transition for the transform property */
}

.icon:hover {
  transform: scale(
    1.8
  ); /* Increase the size on hover (adjust the scale factor as needed) */
}

.move-right-on-hover:hover {
    transform: scale(1.2) translateX(10px); /* Increase size and move right */
  }

.move-left-on-hover:hover {
    transform: scale(1.2) translateX(-10px); /* Increase size and move left */
  }

button{
     font-family: 'CSChatThaiUI', sans-serif !important;  
}