/* @import '~office-ui-fabric-react/dist/css/fabric.css'; */
/* @import 'node_modules/office-ui-fabric-react/dist/css/fabric.css'; */
@import url('https://fonts.googleapis.com/css?family=Sarabun');

/*
@import url('https://fonts.googleapis.com/css?family=Kanit');

 
body,html {
    margin:0;
    padding:0;
}
 */
/* 
#root {
    background-color: rgba(225, 223, 221, 0.2);    
}
 */

@font-face {
    font-family: 'CSChatThaiUI';
    src: local('CSChatThaiUI'), url(./fonts/CSChatThaiUI.ttf) format('opentype');
}

body {
    /* margin-top: 0; */
    font-family: 'CSChatThaiUI', sans-serif !important;
    /* background-color: green; */
    padding: 0;
    margin: 0;
    
    /* background-color: '#FAF9F8'; */
    
}

h1, h2, h3, h4 {  
    font-family: 'CSChatThaiUI', sans-serif !important;  
}

span, p, label {  
    font-family: 'CSChatThaiUI', sans-serif !important;  
    /* font-size: 16px; */
}
 
.ms-Nav-chevronButton {
    font-family: 'CSChatThaiUI', sans-serif !important;
    font-size: 22px;
}

.ms-Nav-linkText {
    font-family: 'CSChatThaiUI', sans-serif !important;
    font-size: 17px;

}

.ms-DetailsRow-cell {
    font-family: 'CSChatThaiUI', sans-serif !important;
    font-size: 14px;

}
/* 
.ms-Nav-linkText-linkText-128 {
    font-family: 'Sarabun', sans-serif !important;
    font-size: 14px;

}

.ms-Button-label {
    font-family: 'CSChatThaiUI', sans-serif !important;
    font-size: 18px;
    font-weight: 500;

}
*/
.ms-ContextualMenu-itemText {
    font-family: 'CSChatThaiUI', sans-serif !important;
    font-size: 18px;
    /* margin-top: 10px; */

}

.ms-Pivot-text {
    font-family: 'CSChatThaiUI', sans-serif !important;
    font-size: 16px;
    /* margin-top: 10px; */

}

.ms-DetailsHeader-cellName {
    font-family: 'CSChatThaiUI', sans-serif !important;
    font-size: 15px;
    font-weight: 600;
    /* text-align: 'center' !important; */
    /* margin-top: 10px; */

}

.ms-DocumentCardTitle {
    font-family: 'CSChatThaiUI', sans-serif !important;
/*    font-size: 18px;
    font-weight: 600;
     margin-top: 10px; */

}
 
.ms-Nav {
    background-color: white;
    border: none;
    font-family: 'Sarabun', sans-serif !important;
}

.ms-Grid-col {
    padding: 0;
    margin: 0;
    border: none;
}

.ms-Grid-row {
    border: none; 
    
}
 
.ms-Grid {
    padding: 0;
    margin: 0;
    background-color: white;
    border: none;
}
 
.DraftEditor-root {
  font-size: 14px;
  font-family: 'Sarabun', sans-serif;
}

.font-preview {
  font-size: 24px;
  font-family: 'Sarabun', sans-serif;
}

.public-DraftStyleDefault-block > div {
  margin: 0px !important;
  font-size: 24px;
}